<template>
  <v-container
    id="recipes"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="showRecipesPacks && tabActive !== 0 ? recipesPacksHeaders : headers"
      :items="tableItemsFiltered"
      :search="search"
      :no-data-text="showRecipesPacks && tabActive !== 0 ? str['recipes_packs_empty'] : str['recipes_empty']"
      :no-results-text="showRecipesPacks && tabActive !== 0 ? str['recipes_packs_empty'] : str['recipes_empty']"
      class="elevation-1"
      @click:row="editItem"
    >
      <template v-slot:top>
        <v-toolbar
          v-if="showRecipesPacks"
          flat
          class="toolbar-adjust table-toolbar-no-padding-sides pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-1"
            >
              <v-tabs
                v-model="tabActive"
                background-color="transparent"
                color="secondary"
                style="margin-top: 0;"
                grow
                show-arrows
                @change="changeTab"
              >
                <v-tab
                  v-for="tab in tabs"
                  :key="tab.value"
                >
                  {{ str[tab.label] ? str[tab.label] : tab.label }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-toolbar
          v-if="parentCategories && parentCategories.length && (!showRecipesPacks || tabActive === 0)"
          flat
          class="toolbar-adjust table-toolbar-no-padding-sides pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-1"
            >
              <v-tabs
                v-model="tabParentCategoryActive"
                background-color="transparent"
                color="secondary"
                style="margin-top: 0;"
                grow
                show-arrows
              >
                <v-tab
                  v-for="parentCategoryTab in parentCategories"
                  :key="parentCategoryTab.value"
                >
                  {{ str[parentCategoryTab.label] ? str[parentCategoryTab.label] : parentCategoryTab.label }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            v-if="showExportCsv && editPermission"
            color="secondary"
            dark
            class="mb-2"
            @click="exportCsv()"
            style="margin-right: 10px;"
          >
            {{ str['export'] }}
          </v-btn>
          <v-btn
            v-if="editPermission"
            color="success"
            dark
            class="mb-2"
            @click="newItem()"
          >
            {{ str['add'] }}
          </v-btn>
        </v-toolbar>
        <v-toolbar
          v-if="categories && categories.length && (!showRecipesPacks || (showRecipesPacks && tabActive < 1))"
          flat
          class="toolbar-adjust"
        >
          <v-row>
            <v-col
              :cols="12"
              :lg="4"
              :md="4"
              :sm="12"
            >
              <v-autocomplete
                v-model="filters.category"
                :label="str['category']"
                item-text="label"
                item-value="value"
                :items="categories"
                :no-data-text="str['no_data']"
                hide-details
                multiple
                clearable
                style="max-width: 300px"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.category="{ item }">
        {{ categoriesDict[item.category] ? categoriesDict[item.category] : '-' }}
      </template>
      <template v-slot:item.description="{ item }">
        <div
          v-if="item.description"
          class="list-commas"
        >
          <span
            v-for="recId in item.description"
          >
            {{ recipesDict[recId] ? recipesDict[recId].name : recId }}
          </span>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="showCopyJson"
          color="orange lighten-3"
          style="margin-right: 10px;"
          @click.stop="copyJson(item)"
        >
          mdi-content-copy
        </v-icon>
        <v-icon
          v-if="!showRecipesPacks || (showRecipesPacks && tabActive < 1)"
          color="teal lighten-2"
          style="margin-right: 10px;"
          @click.stop="duplicateRecipe(item)"
        >
          mdi-content-duplicate
        </v-icon>
        <v-icon
          small
          class="mr-0"
          color="error"
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogRecipesPack"
      persistent
      max-width="750px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ recipesPackToEdit ? str['edit_recipes_pack'] : str['new_recipes_pack'] }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-for="item in recipesPackData"
                :key="item.id"
                cols="12"
              >
                <v-text-field
                  v-if="item.type === 'input'"
                  v-model="item.value"
                  :label="item.title"
                />

                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title"
                  item-text="name"
                  item-value="id"
                  :items="item.items"
                  :multiple="item.multiple"
                  :no-data-text="str['no_data']"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeRecipesPack"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="saveRecipesPack"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    data () {
      const user = Utils.getUser()
      const editPermission = Utils.hasPermission('receipt_list_edit')
      const showRecipesPacks = user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_recipes_packs ? true : false
      const showCopyJson = user && user.configurations && user.configurations.recipe && user.configurations.recipe.show_copy_json ? true : false
      const showExportCsv = user && user.configurations && user.configurations.recipe && user.configurations.recipe.show_export_csv ? true : false
      const categories = Utils.getRecipeCategories()
      const headers = [
        { text: window.strings['id'], value: 'id', width: 80 },
        { text: window.strings['name'], value: 'name', align: 'left', width: 200 },
        { text: window.strings['calories_kcal'], value: 'calories', align: 'center', width: 120 },
        { text: window.strings['protein_g'], value: 'protein', align: 'center', width: 110 },
        { text: window.strings['carbohydrates_g'], value: 'carbs', align: 'center', width: 170 },
        { text: window.strings['fat_g'], value: 'fat', align: 'center', width: 110 },
      ]
      if (categories && categories.length) {
        headers.splice(2, 0, { text: window.strings['category'], value: 'category', align: 'center', width: 110 })
      }
      if (editPermission) {
        headers.push({ text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: showCopyJson ? 120 : 80 })
      }
      const recipesPacksHeaders = [
        { text: window.strings['name'], value: 'name', align: 'left' },
        { text: window.strings['recipes'], value: 'description', align: 'center' },
      ]
      if (editPermission) {
        recipesPacksHeaders.push({ text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 80 })
      }
      
      return {
        str: window.strings,
        user: user,
        editPermission: editPermission,
        showRecipesPacks: showRecipesPacks,
        showCopyJson: showCopyJson,
        showExportCsv: showExportCsv,
        recipes: [],
        recipesDict: {},
        recipesPacksType: 101,
        recipesPacks: [],
        recipesPacksRequestDone: false,
        search: '',
        headers: headers,
        recipesPacksHeaders: recipesPacksHeaders,
        tabs: [{
          label: 'recipes',
          value: 0
        }, {
          label: 'recipes_packs',
          value: 1
        }],
        tabActive: 0,
        categories: categories,
        categoriesDict: this.getCategoriesDict(),
        tabParentCategoryActive: 0,
        parentCategories: Utils.getRecipeParentCategories(),
        filters: {
          category: null,
        },
        dialogRecipesPack: false,
        recipesPackData: [{
          type: 'input',
          id: 'name',
          title: window.strings['name'],
          value: '',
          required: true,
        }, {
          type: 'select',
          multiple: true,
          id: 'description',
          title: window.strings['recipes'],
          items: [],
          value: '',
          required: true,
        }],
        recipesPackToEdit: null,
      }
    },
    computed: {
      tableItemsFiltered() {
        const self = this
        if (this.showRecipesPacks && this.tabActive !== 0) {
          return this.recipesPacks
        } else {
          let recipes = this.recipes
          recipes = recipes.filter(function (item) {
            return self.tabParentCategoryActive === item.parent_category
          })
          if (this.filters.category && this.filters.category.length) {
            recipes = recipes.filter(function (item) {
              return self.filters.category.indexOf(item.category) > -1
            })
          }
          return recipes
        }
      },
    },
    beforeMount: function () {
      this.main()
    },
    methods: {
      main: function () {
        if (!this.user) {
          return false
        }
        this.getRecipes()
        if (!window.useResetCacheSockets) {
          Utils.removeRequestCache('recipes')
        }
      },
      getCategoriesDict: function () {
        const categoriesObj = {}
        const items = Utils.getRecipeCategories()
        if (items) {
          for (let i = 0; i < items.length; i++) {
            categoriesObj[items[i].value] = items[i].label
          }
        }
        return categoriesObj
      },
      changeTab: function () {
        if (this.showRecipesPacks && !this.recipesPacksRequestDone && this.tabs[this.tabActive] && this.tabs[this.tabActive].label === 'recipes_packs') {
          this.recipesPacksRequestDone = true
          this.getRecipesPacks()
        }
      },
      getRecipes: function () {
        const self = this
        this.$isLoading(true)
        Api.getRecipes({
          photo: false
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.recipes = response.data
            const recipesDict = {}
            response.data.forEach(function (item) {
              recipesDict[item.id] = item
            })
            self.recipesDict = recipesDict
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getRecipesPacks: function () {
        const self = this
        this.$isLoading(true)
        Api.getContents({
          type: this.recipesPacksType,
          useCache: true,
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].description) {
                response.data[i].description = JSON.parse(response.data[i].description)
              }
            }
            self.recipesPacks = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      newItem () {
        if (this.showRecipesPacks && this.tabActive !== 0) {
          this.newRecipesPack()
        } else {
          this.newRecipe()
        }
      },
      newRecipe () {
        Utils.removeStorage('recipe')
        delete window.recipesList
        this.$router.navigate.push({ path: '/home/recipe' })
      },
      newRecipesPack () {
        this.recipesPackToEdit = null
        this.setRecipesPack()
        this.dialogRecipesPack = true
      },
      editItem (item) {
        if (this.showRecipesPacks && this.tabActive !== 0) {
          this.editRecipesPack(item)
        } else {
          this.editRecipe(item)
        }
      },
      editRecipe (item) {
        Utils.setStorage('recipe', item)
        window.recipesList = this.recipes
        this.$router.navigate.push({ path: '/home/recipe' })
      },
      editRecipesPack (item) {
        this.recipesPackToEdit = item
        this.setRecipesPack()
        this.dialogRecipesPack = true
      },
      duplicateRecipe(item) {
        const self = this
        this.$confirm(
          window.strings['want_duplicate_recipe'] + (item ? (' "' + item.name + '"') : '') + '?',
          '',
          'info',
          Utils.getAlertOptions(true, false)
        ).then(() => {
          Api.addRecipe(self.getRecipeToSave(item), function (response) {
            if (response.success) {
              self.getRecipes()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      getRecipeToSave (item) {
        const recipe = JSON.parse(JSON.stringify(item))
        if (recipe.extra_data && typeof recipe.extra_data !== 'string') {
          recipe.extra_data = JSON.stringify(recipe.extra_data)
        }
        const keys = ['value', 'food_id', 'details']
        for (let f = 0; f < recipe.foods.length; f++) {
          recipe.foods[f].value = parseFloat(recipe.foods[f].value)
          for (const key in recipe.foods[f]) {
            if (keys.indexOf(key) === -1) {
              delete recipe.foods[f][key]
            }
          }
        }
        return recipe
      },
      deleteItem (item) {
        if (this.showRecipesPacks && this.tabActive !== 0) {
          this.deleteRecipesPack(item)
        } else {
          this.deleteRecipe(item)
        }
      },
      deleteRecipe (item) {
        const self = this
        const id = item.id

        this.$confirm(
          window.strings['want_delete_recipe'] + (item ? (' "' + item.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteRecipe(id, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.recipes.length; i++) {
                if (self.recipes[i].id === id) {
                  self.recipes.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      deleteRecipesPack (item) {
        const self = this
        const id = item.id

        this.$confirm(
          window.strings['want_delete_recipe_pack'] + (item ? (' "' + item.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteContent({
            id: id
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.getRecipesPacks()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      closeRecipesPack () {
        this.dialogRecipesPack = false
      },
      saveRecipesPack () {
        const self = this
        const data = this.getRecipesPackData()

        if (data) {
          this.$isLoading(true)

          if (data.id) {
            Api.updateContent(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeRecipesPack()
                self.getRecipesPacks()
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            Api.newContent(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeRecipesPack()
                self.getRecipesPacks()
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
        }
      },
      setRecipesPack: function () {
        for (let i = 0; i < this.recipesPackData.length; i++) {
          if (this.recipesPackData[i].id === 'description') {
            this.recipesPackData[i].items = this.recipes
          }
          this.recipesPackData[i].value = this.recipesPackToEdit ? this.recipesPackToEdit[this.recipesPackData[i].id] : ''
        }
      },
      getRecipesPackData: function () {
        const data = {}
        for (let i = 0; i < this.recipesPackData.length; i++) {
          if (this.recipesPackData[i].required && (!this.recipesPackData[i].value || (this.recipesPackData[i].value && !this.recipesPackData[i].value.length))) {
            this.$alert(
              window.strings['fields_to_be_filled'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
            return false
          }
          if (this.recipesPackData[i].id === 'description') {
            data[this.recipesPackData[i].id] = JSON.stringify(this.recipesPackData[i].value)
          } else {
            data[this.recipesPackData[i].id] = this.recipesPackData[i].value
          }
        }

        data.status = 0
        data.type = this.recipesPacksType

        if (this.recipesPackToEdit) {
          data.id = this.recipesPackToEdit.id
        }

        return data
      },
      copyJson: function (item) {
        try {
          if (navigator && navigator.clipboard) {
            const foodFields = ['food_id', 'type', 'value', 'calories', 'protein', 'carbs', 'fat']
            const json = {
              foods: []
            }
            item.foods.forEach(function (food) {
              const foodObj = {}
              foodFields.forEach(function (key) {
                foodObj[key] = food[key]
              })
              json.foods.push(foodObj)
            })
            json.foods = json.foods.sort((a, b) => b.type - a.type)
            const textarea = document.createElement('textarea')
            textarea.value = JSON.stringify(json)
            document.body.appendChild(textarea)
            textarea.select()
            document.execCommand('copy')
            document.body.removeChild(textarea)
            this.$notify({
              group: 'alert',
              text: window.strings['data_copied'],
              duration: 2000,
            })
          }
        } catch (error) {
          this.$alert(
            window.strings['common_error'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      },
      exportCsv () {
        const headers = [{
          value: 'id',
          text: window.strings['id']
        }, {
          value: 'name',
          text: window.strings['name']
        }]
        Utils.exportCsv({
          title: window.strings['recipes'],
          headers: headers,
          data: this.recipes
        })
      },
    },
  }
</script>
